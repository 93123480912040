@charset "UTF-8";
/* Common */
/* Layout */
/* Topbar */
/* Tab */
/******************************/
/*           THEME            */
/******************************/
/* Headers */
/* Contents */
/* Panels */
/* Forms */
/* List Items */
/* Messages */
/* Data */
/* Predefined Colors */
body .p-widget,
body .p-widget .p-widget {
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  text-decoration: none; }
body .p-widget-content {
  background-color: #ffffff;
  border: 1px solid #eaeaea; }
body .p-widget-header {
  background-color: #f1f1f1;
  color: #858585;
  border: 1px solid #eaeaea;
  font-weight: bold; }
body .p-state-active, body .p-state-highlight {
  background-color: #59c429;
  color: #ffffff; }
body .p-state-error, body .p-state-default.p-state-error {
  background-color: #f0d1d0;
  border: solid 1px #e96d66; }
body .p-icon {
  width: 16px;
  height: 16px;
  line-height: 16px; }
body .p-state-disabled,
body .p-widget:disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important; }
  body .p-state-disabled *,
  body .p-widget:disabled * {
    cursor: default !important; }
body .p-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
body .p-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .p-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body .p-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px; }
body .p-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body .p-widget-overlay {
  background-color: #424242;
  opacity: 0.7;
  filter: alpha(opacity=70); }
body a {
  color: #469a20;
  text-decoration: none; }
  body a:hover {
    color: #469a20; }
body .ajax-loader {
  font-size: 28px;
  color: #59c429; }

body {
  /* Validation */ }
  body .p-inputtext {
    background: #ffffff;
    padding: 0.308em;
    border: 1px solid #cfcfcf;
    -moz-transition: border-color 0.3s, box-shadow 0.3s;
    -o-transition: border-color 0.3s, box-shadow 0.3s;
    -webkit-transition: border-color 0.3s, box-shadow 0.3s;
    transition: border-color 0.3s, box-shadow 0.3s;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 13px;
    color: #777777; }
    body .p-inputtext:enabled:hover:not(.p-state-error) {
      border-color: #afafaf; }
    body .p-inputtext:enabled:focus:not(.p-state-error) {
      border-color: #59c429;
      -moz-box-shadow: 0 0 3px 0 rgba(143, 223, 50, 0.5), inset 0 0 1.5px 0 rgba(0, 0, 0, 0.07);
      -webkit-box-shadow: 0 0 3px 0 rgba(143, 223, 50, 0.5), inset 0 0 1.5px 0 rgba(0, 0, 0, 0.07);
      box-shadow: 0 0 3px 0 rgba(143, 223, 50, 0.5), inset 0 0 1.5px 0 rgba(0, 0, 0, 0.07); }
    body .p-inputtext.ng-dirty.ng-invalid {
      background-color: #f0d1d0; }
      body .p-inputtext.ng-dirty.ng-invalid:hover {
        border-color: #e96d66; }
      body .p-inputtext.ng-dirty.ng-invalid:focus {
        border-color: #e96d66;
        box-shadow: none; }
      body .p-inputtext.ng-dirty.ng-invalid:active {
        border-color: #e96d66; }
  body .p-button {
    color: #ffffff;
    background-color: #59c429;
    border: 1px solid transparent;
    font-size: 13px;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    body .p-button:enabled:hover {
      background-color: #4faf25; }
    body .p-button:enabled:focus {
      outline: 0 none;
      background-color: #4faf25; }
    body .p-button:enabled:active {
      background-color: #469a20; }
    body .p-button.p-button-text-only .p-button-text {
      padding: .308em .6em; }
    body .p-button.p-button-text-icon-left .p-button-text {
      padding: .308em .6em .308em 2em; }
    body .p-button.p-button-text-icon-left .fa {
      margin-top: -.5em; }
    body .p-button.p-button-text-icon-right .p-button-text {
      padding: .308em 2em .308em .6em; }
    body .p-button.p-button-text-icon-right .fa {
      margin-top: -.5em; }
    body .p-button.p-button-icon-only .fa {
      margin-left: -.6em; }
    body .p-button.p-button-icon-only .p-button-text {
      padding: .308em; }
    body .p-button.blue-btn {
      background-color: #6ec5ff; }
      body .p-button.blue-btn:hover {
        background-color: #55bbff; }
      body .p-button.blue-btn:focus {
        background-color: #55bbff; }
      body .p-button.blue-btn:active {
        background-color: #3bb1ff; }
    body .p-button.purple-btn {
      background-color: #985edb; }
      body .p-button.purple-btn:hover {
        background-color: #8b49d6; }
      body .p-button.purple-btn:focus {
        background-color: #8b49d6; }
      body .p-button.purple-btn:active {
        background-color: #7d34d2; }
    body .p-button.orange-btn {
      background-color: #F1B009; }
      body .p-button.orange-btn:hover {
        background-color: #d89e08; }
      body .p-button.orange-btn:focus {
        background-color: #d89e08; }
      body .p-button.orange-btn:active {
        background-color: #c08c07; }
    body .p-button.pink-btn {
      background-color: #f18983; }
      body .p-button.pink-btn:hover {
        background-color: #ee736c; }
      body .p-button.pink-btn:focus {
        background-color: #ee736c; }
      body .p-button.pink-btn:active {
        background-color: #ec5d55; }
    body .p-button.green-btn {
      background-color: #59c429; }
      body .p-button.green-btn:hover {
        background-color: #4faf25; }
      body .p-button.green-btn:focus {
        background-color: #4faf25; }
      body .p-button.green-btn:active {
        background-color: #469a20; }
    body .p-button.black-btn {
      background-color: #777777; }
      body .p-button.black-btn:hover {
        background-color: #6a6a6a; }
      body .p-button.black-btn:focus {
        background-color: #6a6a6a; }
      body .p-button.black-btn:active {
        background-color: #5e5e5e; }
    body .p-button.raised-btn {
      -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
      box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16); }
    body .p-button.rounded-btn {
      -moz-border-radius: 15px;
      -webkit-border-radius: 15px;
      border-radius: 15px; }
  body .p-fluid .p-button-icon-only {
    width: auto; }
  body .p-chkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 16px;
    height: 16px; }
    body .p-chkbox .p-chkbox-box {
      border: 1px solid #cfcfcf;
      background-color: #ffffff;
      width: 16px;
      height: 16px;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      body .p-chkbox .p-chkbox-box:not(.p-state-disabled):not(.p-state-active):hover {
        border: 1px solid #afafaf; }
      body .p-chkbox .p-chkbox-box.p-state-active {
        border: 1px solid #59c429;
        background-color: #59c429;
        color: #ffffff; }
      body .p-chkbox .p-chkbox-box.p-state-focus {
        border: 1px solid #59c429;
        -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
      body .p-chkbox .p-chkbox-box .p-chkbox-icon {
        overflow: hidden;
        position: relative;
        top: .1em;
        font-size: 12px; }
  body .p-radiobutton {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 16px;
    height: 16px; }
    body .p-radiobutton .p-radiobutton-box {
      border: 1px solid #cfcfcf;
      background-color: #ffffff;
      width: 16px;
      height: 16px;
      text-align: center;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; }
      body .p-radiobutton .p-radiobutton-box:not(.p-state-disabled):not(.p-state-active):hover {
        border: 1px solid #afafaf; }
      body .p-radiobutton .p-radiobutton-box.p-state-active {
        border: 1px solid #afafaf;
        background-color: #ffffff;
        color: #ffffff; }
        body .p-radiobutton .p-radiobutton-box.p-state-active .p-radiobutton-icon {
          background-color: #59c429; }
      body .p-radiobutton .p-radiobutton-box.p-state-focus {
        border: 1px solid #59c429;
        -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
      body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: transparent;
        width: 8px;
        height: 8px;
        display: inline-block; }
        body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon:before {
          display: none; }
  body .p-inputswitch {
    height: 2em;
    -moz-transition: border-color 0.3s;
    -o-transition: border-color 0.3s;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s; }
    body .p-inputswitch .p-inputswitch-on {
      background-color: #59c429;
      color: #ffffff;
      font-weight: normal; }
    body .p-inputswitch .p-inputswitch-off {
      background-color: #a8acb1;
      color: #ffffff;
      font-weight: normal; }
    body .p-inputswitch .p-inputswitch-on,
    body .p-inputswitch .p-inputswitch-off {
      line-height: 2em; }
    body .p-inputswitch:not(.p-state-disabled):hover {
      border-color: #afafaf; }
    body .p-inputswitch .p-inputswitch-handle {
      background-color: #ffffff;
      border: 1px solid transparent;
      box-sizing: border-box; }
      body .p-inputswitch .p-inputswitch-handle.p-state-focus {
        border: 1px solid #59c429;
        -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
  body .p-autocomplete .p-autocomplete-dropdown {
    width: 2em;
    background-color: #c0c0c0; }
    body .p-autocomplete .p-autocomplete-dropdown:not(.p-state-disabled):hover {
      background-color: #999999; }
    body .p-autocomplete .p-autocomplete-dropdown:not(.p-state-disabled):active {
      background-color: #999999; }
    body .p-autocomplete .p-autocomplete-dropdown .fa {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center; }
      body .p-autocomplete .p-autocomplete-dropdown .fa:before {
        content: ""; }
    body .p-autocomplete .p-autocomplete-dropdown.p-button-icon-only .p-button-text {
      padding: 0.308em; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
    padding: 0.308em; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-state-disabled):hover {
      border: 1px solid #afafaf; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-state-disabled).p-state-focus {
      border: 1px solid #59c429;
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
      padding: 0 .25em 0 0;
      vertical-align: top; }
      body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
        font-size: 13px;
        padding: 0;
        margin: 0; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
      font-size: 11px;
      vertical-align: top; }
  body .p-chips > ul.p-inputtext {
    padding: .25em;
    display: inline-block; }
    body .p-chips > ul.p-inputtext:not(.p-state-disabled):hover {
      border: 1px solid #afafaf; }
    body .p-chips > ul.p-inputtext:not(.p-state-disabled).p-state-focus {
      border: 1px solid #59c429;
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
    body .p-chips > ul.p-inputtext .p-chips-input-token input {
      font-size: 13px;
      padding: 0;
      margin: 0; }
      body .p-chips > ul.p-inputtext .p-chips-input-token input:hover {
        border: 0 none; }
      body .p-chips > ul.p-inputtext .p-chips-input-token input:focus {
        border: 0 none;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none; }
    body .p-chips > ul.p-inputtext .p-chips-token {
      font-size: 1em; }
  body .p-autocomplete-panel {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
    body .p-autocomplete-panel .p-autocomplete-items {
      padding: 0; }
      body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
        margin: 0;
        padding: 0.5em 1em;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
      body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-group {
        padding: 0.5em 1em;
        background-color: #eaeaea;
        color: #777777; }
  body .p-fluid .p-autocomplete-multiple.p-autocomplete-dd .p-autocomplete-multiple-container {
    width: calc(100% - 2em); }
  body .p-dropdown {
    border: 1px solid #cfcfcf; }
    body .p-dropdown:not(.p-state-disabled):hover {
      border: 1px solid #afafaf; }
    body .p-dropdown .p-dropdown-label:focus,
    body .p-dropdown .p-dropdown-label:hover {
      border: 0 none; }
    body .p-dropdown input.p-dropdown-label:focus.p-inputtext,
    body .p-dropdown input.p-dropdown-label:hover.p-inputtext {
      border: 0 none; }
    body .p-dropdown input.p-dropdown-label + .p-dropdown-trigger span {
      margin-top: .3em; }
    body .p-dropdown .p-dropdown-trigger {
      background-color: #ffffff;
      width: 2em;
      text-align: center;
      padding: 0; }
      body .p-dropdown .p-dropdown-trigger .fa-caret-down {
        color: #848484;
        background: none;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-indent: 0px !important;
        text-align: center; }
        body .p-dropdown .p-dropdown-trigger .fa-caret-down:before {
          content: ""; }
    body .p-dropdown:not(.p-state-disabled).p-state-focus {
      border: 1px solid #59c429;
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
      body .p-dropdown:not(.p-state-disabled).p-state-focus .p-dropdown-trigger .fa-caret-down {
        color: #59c429; }
  body .p-dropdown-panel {
    background-color: #f9f9f9;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
    body .p-dropdown-panel .p-dropdown-filter-container {
      padding: 0.5em 1em; }
      body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter {
        width: 100%;
        box-sizing: border-box;
        padding-right: 20px; }
      body .p-dropdown-panel .p-dropdown-filter-container .fa {
        top: 12px;
        right: 15px; }
    body .p-dropdown-panel .p-dropdown-items {
      padding: 0;
      background-color: #f9f9f9; }
      body .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        margin: 0;
        padding: 0.5em 1em;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-state-highlight):hover {
          background-color: #e2e2e2;
          color: #777777; }
        body .p-dropdown-panel .p-dropdown-items .p-dropdown-item > td {
          padding: 0.5em 1em; }
      body .p-dropdown-panel .p-dropdown-items .p-selectonemenu-item-group {
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        padding: 0.5em 1em;
        background-color: #e0e0e0; }
  body .p-multiselect {
    background: #ffffff;
    border: 1px solid #cfcfcf; }
    body .p-multiselect .p-multiselect-label {
      padding: 0.308em;
      padding-right: 2.3em; }
    body .p-multiselect .p-multiselect-trigger {
      width: 2em;
      box-sizing: border-box;
      padding: 0;
      text-align: center; }
      body .p-multiselect .p-multiselect-trigger .fa {
        margin-top: .425em;
        width: auto;
        background: none;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-indent: 0px !important;
        text-align: center; }
        body .p-multiselect .p-multiselect-trigger .fa:before {
          content: ""; }
    body .p-multiselect:not(.p-state-disabled):hover {
      border: 1px solid #afafaf; }
    body .p-multiselect:not(.p-state-disabled).p-state-focus {
      border: 1px solid #59c429;
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
  body .p-multiselect-panel {
    padding: 0;
    min-width: 12em;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
    body .p-multiselect-panel .p-multiselect-header {
      padding: 0.5em 1em;
      background-color: #eaeaea;
      border: 0 none;
      border-bottom: 1px solid #eaeaea; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
        float: none;
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
          padding: 1px;
          width: 100%;
          box-sizing: border-box;
          text-indent: 1.5em; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .fa {
          color: #777777;
          top: .3em;
          left: 0.2em; }
      body .p-multiselect-panel .p-multiselect-header .p-chkbox {
        margin-right: 8px;
        float: none;
        vertical-align: middle; }
        body .p-multiselect-panel .p-multiselect-header .p-chkbox .p-chkbox-box {
          color: #ffffff; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
        color: #858585;
        font-size: 17px;
        margin: 0;
        padding: 0;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
          padding: 0;
          color: #59c429; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
          outline: 0 none;
          color: #74d947; }
    body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      padding: 0.5em 1em; }
      body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-chkbox {
        position: static;
        margin-top: 0;
        display: inline-block;
        vertical-align: middle; }
      body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
        padding: 0;
        margin: 0 0 0 0.615em;
        display: inline-block;
        vertical-align: middle; }
      body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-state-highlight):hover {
        border-radius: 0;
        background-color: #e2e2e2;
        color: #777777; }
  body .p-listbox {
    padding: 0;
    background-color: #f9f9f9;
    border-color: #eaeaea;
    min-width: 11em; }
    body .p-listbox .p-chkbox {
      margin-right: .5em; }
    body .p-listbox .p-listbox-header {
      padding: 0.5em 1em;
      border: 0 none;
      border-bottom: 1px solid #eaeaea;
      margin: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container input {
        width: 100%;
        padding-left: 1.75em; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container .fa {
        top: .3em;
        left: .5em; }
    body .p-listbox .p-listbox-item {
      margin: 0;
      padding: 0.5em 1em;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-listbox .p-listbox-item.p-state-highlight {
        background-color: #59c429;
        color: #ffffff; }
      body .p-listbox .p-listbox-item > td {
        padding: 0.5em 1em; }
    body .p-listbox:not(.p-state-disabled) .p-listbox-item:not(.p-state-highlight):hover {
      background-color: #e2e2e2;
      color: #777777; }
    body .p-listbox.p-state-disabled .p-chkbox-box:not(.p-state-disabled):not(.p-state-active):hover {
      border: 1px solid #cfcfcf; }
  body .p-editor-container .p-editor-toolbar {
    background-color: #f9f9f9; }
  body .p-editor-container .ql-picker.ql-expanded .ql-picker-label {
    color: #444444; }
  body .p-rating a {
    color: #59c429;
    text-align: center;
    display: inline-block;
    border-bottom: 1px solid transparent; }
    body .p-rating a:focus {
      outline: 0 none; }
  body .p-rating .fa {
    font-size: 20px;
    height: 20px;
    width: 20px; }
    body .p-rating .fa.fa-ban {
      color: #f18983; }
      body .p-rating .fa.fa-ban:hover {
        color: #f49f9a; }
  body .p-spinner input {
    padding-right: 1.8em; }
  body .p-spinner .p-spinner-button {
    z-index: auto;
    background-color: #c0c0c0; }
    body .p-spinner .p-spinner-button:not(.p-state-disabled):hover {
      background-color: #999999; }
    body .p-spinner .p-spinner-button:not(.p-state-disabled):focus {
      background-color: #c0c0c0; }
    body .p-spinner .p-spinner-button:not(.p-state-disabled):active {
      background-color: #737373; }
  body .p-slider {
    background-color: #eaeaea;
    border-color: #eaeaea; }
    body .p-slider.p-slider-horizontal .p-slider-handle {
      top: -.2em; }
    body .p-slider.p-slider-vertical .p-slider-handle {
      left: -.2em; }
    body .p-slider .p-slider-handle {
      background-color: #59c429;
      border-radius: 100%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
    body .p-slider .p-slider-range {
      background-color: #84dd5c; }
    body .p-slider:not(.p-state-disabled) .p-slider-handle:hover {
      background-color: #4faf25; }
  body .p-togglebutton {
    background-color: #eaeaea;
    color: #777777; }
    body .p-togglebutton:not(.p-state-disabled):not(.p-state-active):hover {
      background-color: #a8acb1;
      color: #ffffff; }
    body .p-togglebutton:not(.p-state-disabled):not(.p-state-active).p-state-focus {
      background-color: #eaeaea;
      color: #777777;
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid #59c429; }
    body .p-togglebutton.p-state-active {
      background-color: #59c429;
      color: #ffffff; }
      body .p-togglebutton.p-state-active.p-state-focus {
        background-color: #59c429;
        color: #ffffff;
        -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
  body .p-selectbutton .p-button {
    background-color: #eaeaea;
    color: #777777;
    border: 1px solid transparent;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px; }
    body .p-selectbutton .p-button:not(.p-state-disabled):not(.p-state-active):hover {
      background-color: #a8acb1;
      color: #ffffff; }
    body .p-selectbutton .p-button:not(.p-state-disabled):not(.p-state-active).p-state-focus {
      background-color: #eaeaea;
      color: #777777;
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid #59c429; }
    body .p-selectbutton .p-button.p-state-active {
      background-color: #59c429;
      color: #ffffff;
      border-right-color: #59c429; }
      body .p-selectbutton .p-button.p-state-active.p-state-focus {
        background-color: #59c429;
        color: #ffffff;
        -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); }
    body .p-selectbutton .p-button:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    body .p-selectbutton .p-button:last-child {
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
  @media (max-width: 640px) {
    body .p-buttonset .p-button {
      margin-bottom: 1px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; } }
  body .p-splitbutton.p-buttonset .p-button {
    border: 1px solid transparent; }
  body .p-datepicker {
    padding: 0;
    width: 20em; }
    body .p-datepicker:not(.p-state-disabled) .p-datepicker-header .p-datepicker-prev:hover,
    body .p-datepicker:not(.p-state-disabled) .p-datepicker-header .p-datepicker-next:hover {
      color: #59c429; }
    body .p-datepicker:not(.p-state-disabled) .p-datepicker-header .p-datepicker-prev:focus,
    body .p-datepicker:not(.p-state-disabled) .p-datepicker-header .p-datepicker-next:focus {
      outline: 0 none;
      color: #74d947; }
    body .p-datepicker:not(.p-state-disabled) table td:not(.p-state-disabled) a:not(.p-state-active):not(.p-state-highlight):hover {
      background-color: #e2e2e2; }
    body .p-datepicker .p-datepicker-header {
      padding: 0.5em 1em;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-datepicker .p-datepicker-header .p-datepicker-prev {
        cursor: pointer;
        top: 1em;
        color: #858585;
        background: none;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-indent: 0px !important;
        text-align: center;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s; }
        body .p-datepicker .p-datepicker-header .p-datepicker-prev:before {
          content: ""; }
        body .p-datepicker .p-datepicker-header .p-datepicker-prev span {
          display: none; }
      body .p-datepicker .p-datepicker-header .p-datepicker-next {
        cursor: pointer;
        top: 1em;
        color: #858585;
        background: none;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-indent: 0px !important;
        text-align: center;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s; }
        body .p-datepicker .p-datepicker-header .p-datepicker-next:before {
          content: ""; }
        body .p-datepicker .p-datepicker-header .p-datepicker-next span {
          display: none; }
    body .p-datepicker table {
      font-size: 13px; }
      body .p-datepicker table td {
        padding: 2px; }
        body .p-datepicker table td a, body .p-datepicker table td span {
          min-width: 15px;
          text-align: center;
          color: #777777;
          padding: .35em;
          -moz-border-radius: 3px;
          -webkit-border-radius: 3px;
          border-radius: 3px; }
          body .p-datepicker table td a:hover, body .p-datepicker table td span:hover {
            background-color: #e2e2e2; }
          body .p-datepicker table td a.p-state-active, body .p-datepicker table td span.p-state-active {
            color: #ffffff;
            background-color: #59c429; }
          body .p-datepicker table td a.p-state-highlight, body .p-datepicker table td span.p-state-highlight {
            color: #777777;
            background-color: #eaeaea; }
        body .p-datepicker table td.p-datepicker-today a {
          background-color: #d1d1d1;
          color: #ffffff; }
    body .p-datepicker.p-datepicker-div {
      -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
      body .p-datepicker.p-datepicker-div .p-timepicker-div .p-widget-header {
        padding: 0.5em 1em;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
    body .p-datepicker .p-timepicker a {
      color: #858585; }
      body .p-datepicker .p-timepicker a:hover {
        color: #59c429; }
  body .p-calendar.p-calendar-w-btn .p-inputtext {
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none; }
    body .p-calendar.p-calendar-w-btn .p-inputtext:enabled:hover:not(.p-state-error), body .p-calendar.p-calendar-w-btn .p-inputtext:enabled:focus:not(.p-state-error) {
      border-right: 0 none; }
  body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    width: 2em;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0; }
  body .p-inplace .p-inplace-display {
    padding: 0.308em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-inplace .p-inplace-display:not(.p-state-disabled):hover {
      background-color: #e2e2e2;
      color: #777777; }
  body .p-fileupload .p-fileupload-buttonbar {
    padding: 0.5em 1em;
    border-bottom: 0 none; }
    body .p-fileupload .p-fileupload-buttonbar .p-button {
      margin-right: 8px; }
      body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-upload .p-icon {
        background: none;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-indent: 0px !important;
        text-align: center; }
        body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-upload .p-icon:before {
          content: ""; }
  body .p-fileupload-choose:not(.p-state-disabled):hover,
  body .p-fileupload-choose.p-state-focus {
    background-color: #4faf25; }
  body .p-fileupload-choose:not(.p-state-disabled):active {
    background-color: #469a20; }
  body .p-password-panel {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
  body .p-inputgroup .p-inputgroup-addon {
    border-color: #cfcfcf;
    background-color: #e6e6e6;
    padding: 0.308em; }
    body .p-inputgroup .p-inputgroup-addon:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    body .p-inputgroup .p-inputgroup-addon:last-child {
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
    body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox, body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton {
      padding: 0.286em 0.857em; }
  body .p-inputgroup .p-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .p-inputgroup .p-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  body .p-inputtext.ng-dirty.ng-invalid,
  body p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
  body p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
  body p-calendar.ng-dirty.ng-invalid > .p-inputtext,
  body p-chips.ng-dirty.ng-invalid > .p-inputtext,
  body p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
  body p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
  body p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
  body p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
  body p-listbox.ng-dirty.ng-invalid .p-inputtext,
  body p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
  body p-spinner.ng-dirty.ng-invalid > .p-inputtext,
  body p-selectbutton.ng-dirty.ng-invalid .p-button,
  body p-togglebutton.ng-dirty.ng-invalid .p-button {
    border-color: #e96d66; }

body .p-panel {
  padding: 0; }
  body .p-panel .p-panel-titlebar {
    border: 0 none;
    border-bottom: 1px solid #eaeaea;
    padding: 0.5em 1em;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomleft: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-border-radius-bottomright: 0px;
    -webkit-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px; }
    body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
      position: relative;
      color: #858585;
      border: 1px solid transparent;
      -moz-transition: color 0.3s;
      -o-transition: color 0.3s;
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
        color: #858585; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:focus {
        outline: 0 none;
        border: 1px solid #59c429; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon .fa-minus:hover {
        color: #6c6c6c; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon .fa-plus:hover {
        color: #6c6c6c; }
  body .p-panel .p-panel-content {
    height: 100%;
    box-sizing: border-box;
    padding: 0.5em 1em; }
  body .p-panel .p-panel-footer {
    padding: 0.5em 1em;
    border: 0 none;
    border-top: 1px solid #eaeaea;
    margin: 0; }
  body .p-panel.p-panel-collapsed-h .p-panel-titlebar {
    padding-left: 40px; }
body .p-fieldset {
  padding: 0 0 .5em 0; }
  body .p-fieldset .p-fieldset-legend {
    color: #858585;
    background-color: #f1f1f1;
    margin-left: .75em;
    padding: 0.5em 1em;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    body .p-fieldset .p-fieldset-legend .p-fieldset-toggler {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-right: .5em;
      color: #858585; }
    body .p-fieldset .p-fieldset-legend:hover {
      background-color: #dcdcdc; }
      body .p-fieldset .p-fieldset-legend:hover .p-fieldset-toggler {
        color: #858585; }
  body .p-fieldset .p-fieldset-content {
    padding: 0.5em 1em; }
body .p-notificationbar {
  background-color: #59c429;
  color: #ffffff; }
body .p-accordion .p-accordion-header {
  background-color: #f1f1f1;
  padding: 0;
  color: #858585;
  font-size: 13px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }
  body .p-accordion .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover {
    background-color: #dcdcdc;
    color: #6c6c6c; }
    body .p-accordion .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover .fa {
      color: #858585; }
  body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active {
    background-color: #ffffff;
    color: #777777;
    border-bottom: 1px solid #59c429;
    z-index: 1;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a {
      color: #59c429; }
    body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active span {
      color: #59c429; }
    body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active .fa {
      color: #59c429; }
  body .p-accordion .p-accordion-header .fa-caret-right {
    background: none;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center; }
    body .p-accordion .p-accordion-header .fa-caret-right:before {
      content: ""; }
  body .p-accordion .p-accordion-header .fa-caret-down {
    background: none;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center; }
    body .p-accordion .p-accordion-header .fa-caret-down:before {
      content: ""; }
  body .p-accordion .p-accordion-header > a {
    padding: .5em 1em .5em 2em;
    color: #858585;
    outline: 0 none; }
  body .p-accordion .p-accordion-header > span {
    color: #858585; }
body .p-accordion .p-accordion-content {
  padding: 0.5em 1em; }
body .p-tabview {
  padding: 0; }
  body .p-tabview.p-tabview-top, body .p-tabview.p-tabview-bottom, body .p-tabview.p-tabview-left, body .p-tabview.p-tabview-right {
    border: 0 none; }
    body .p-tabview.p-tabview-top .p-tabview-nav, body .p-tabview.p-tabview-bottom .p-tabview-nav, body .p-tabview.p-tabview-left .p-tabview-nav, body .p-tabview.p-tabview-right .p-tabview-nav {
      padding: 0;
      border: 0 none;
      border-bottom: 1px solid #eaeaea;
      background: #ffffff;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-tabview.p-tabview-top .p-tabview-nav li, body .p-tabview.p-tabview-bottom .p-tabview-nav li, body .p-tabview.p-tabview-left .p-tabview-nav li, body .p-tabview.p-tabview-right .p-tabview-nav li {
        position: static;
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        margin: 0;
        box-sizing: border-box;
        background-color: #f1f1f1; }
        body .p-tabview.p-tabview-top .p-tabview-nav li a, body .p-tabview.p-tabview-bottom .p-tabview-nav li a, body .p-tabview.p-tabview-left .p-tabview-nav li a, body .p-tabview.p-tabview-right .p-tabview-nav li a {
          padding: 0.5em 1em;
          color: #858585; }
          body .p-tabview.p-tabview-top .p-tabview-nav li a:focus, body .p-tabview.p-tabview-bottom .p-tabview-nav li a:focus, body .p-tabview.p-tabview-left .p-tabview-nav li a:focus, body .p-tabview.p-tabview-right .p-tabview-nav li a:focus {
            outline: 0 none; }
          body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-left-icon {
            margin-right: .25em; }
          body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-right-icon {
            margin-left: .25em; }
        body .p-tabview.p-tabview-top .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li .p-tabview-close {
          margin-top: .65em; }
        body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover {
          background-color: #dcdcdc; }
        body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active, body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active, body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active {
          background-color: #59c429; }
          body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active a {
            color: #ffffff; }
          body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active .p-icon-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active .p-icon-close, body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active .p-icon-close, body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active .p-icon-close {
            color: #ffffff; }
          body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active.p-tabview-outline, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active.p-tabview-outline, body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active.p-tabview-outline, body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active.p-tabview-outline {
            background-color: #64d432; }
        body .p-tabview.p-tabview-top .p-tabview-nav li .p-icon-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li .p-icon-close, body .p-tabview.p-tabview-left .p-tabview-nav li .p-icon-close, body .p-tabview.p-tabview-right .p-tabview-nav li .p-icon-close {
          color: #858585;
          margin-top: .5em; }
  body .p-tabview.p-tabview-left .p-tabview-nav li, body .p-tabview.p-tabview-right .p-tabview-nav li {
    width: 100%; }
  body .p-tabview .p-tabview-panel {
    padding: 0.5em 1em;
    border-bottom: 1px solid #eaeaea; }
body .p-scrollpanel .p-scrollpanel-drag {
  background-color: #a8acb1; }
body .p-scrollpanel .p-scrollpanel-track {
  background-color: #f0f3f5; }
body .p-scrollpanel .p-scrollpanel-corner {
  background-color: #f0f3f5; }
body .p-toolbar {
  padding: 0.5em 1em; }

@media (max-width: 640px) {
  body .p-panelgrid .p-grid-responsive .p-grid-row {
    border: 0 none; } }
body .p-paginator {
  padding: 0.5em 1em;
  background-color: #f1f1f1; }
  body .p-paginator .p-paginator-first, body .p-paginator .p-paginator-prev, body .p-paginator .p-paginator-next, body .p-paginator .p-paginator-last {
    color: #848484;
    border: 1px solid transparent;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
    body .p-paginator .p-paginator-first:not(.p-state-disabled):not(.p-state-active):hover, body .p-paginator .p-paginator-prev:not(.p-state-disabled):not(.p-state-active):hover, body .p-paginator .p-paginator-next:not(.p-state-disabled):not(.p-state-active):hover, body .p-paginator .p-paginator-last:not(.p-state-disabled):not(.p-state-active):hover {
      color: #59c429; }
    body .p-paginator .p-paginator-first:focus, body .p-paginator .p-paginator-prev:focus, body .p-paginator .p-paginator-next:focus, body .p-paginator .p-paginator-last:focus {
      outline: 0 none;
      border: 1px solid #59c429; }
  body .p-paginator .p-paginator-pages .p-paginator-page {
    color: #848484;
    padding: .1em .5em;
    border: 1px solid transparent;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
    body .p-paginator .p-paginator-pages .p-paginator-page.p-state-active {
      color: #ffffff; }
    body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-state-active):hover {
      background-color: #e2e2e2; }
    body .p-paginator .p-paginator-pages .p-paginator-page:focus {
      outline: 0 none;
      border: 1px solid #59c429; }
body .p-datatable .p-datatable-header, body .p-datatable .p-datatable-footer {
  padding: 0.5em 1em; }
body .p-datatable .p-datatable-header {
  border-color: #eaeaea; }
body .p-datatable .p-paginator {
  padding: 0.5em 1em; }
body .p-datatable .p-datatable-thead > tr > th {
  background-color: #ffffff;
  padding: 0.5em 1em;
  border-color: #eaeaea;
  color: #777777; }
  body .p-datatable .p-datatable-thead > tr > th.p-sortable-column:not(.p-state-active):hover {
    background-color: #d1d1d1; }
  body .p-datatable .p-datatable-thead > tr > th.p-state-active {
    background-color: #59c429;
    color: #ffffff; }
  body .p-datatable .p-datatable-thead > tr > th:focus {
    outline: 0 none;
    background-color: #f0f3f5; }
    body .p-datatable .p-datatable-thead > tr > th:focus.p-state-active {
      background-color: #64d432; }
  body .p-datatable .p-datatable-thead > tr > th .p-column-title {
    font-weight: 700; }
body .p-datatable .p-datatable-tfoot > tr > td {
  background-color: #ffffff;
  padding: 0.5em 1em;
  border-color: #eaeaea;
  color: #777777; }
body .p-datatable .p-datatable-data tr.p-datatable-even {
  background-color: #ffffff; }
  body .p-datatable .p-datatable-data tr.p-datatable-even.p-state-highlight {
    background-color: #59c429;
    color: #ffffff;
    border-color: #4faf25; }
body .p-datatable .p-datatable-data tr.p-datatable-odd {
  background-color: #f8f8f8; }
  body .p-datatable .p-datatable-data tr.p-datatable-odd.p-state-highlight {
    background-color: #59c429;
    color: #ffffff;
    border-color: #4faf25; }
body .p-datatable .p-datatable-data tr.p-state-highlight {
  border-color: #4faf25; }
body .p-datatable .p-datatable-data tr td {
  padding: 0.5em 1em;
  border-color: #eaeaea; }
  body .p-datatable .p-datatable-data tr td.p-editable-column.p-cell-editing .p-inputtext {
    border-color: #59c429; }
  body .p-datatable .p-datatable-data tr td.p-editable-column.p-cell-editing .p-datepicker table td a,
  body .p-datatable .p-datatable-data tr td.p-editable-column.p-cell-editing .p-datepicker table td span {
    min-width: 1.8em; }
  body .p-datatable .p-datatable-data tr td .p-row-toggler {
    color: #777777;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
    body .p-datatable .p-datatable-data tr td .p-row-toggler.fa-chevron-circle-right {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center; }
      body .p-datatable .p-datatable-data tr td .p-row-toggler.fa-chevron-circle-right:before {
        content: ""; }
    body .p-datatable .p-datatable-data tr td .p-row-toggler.fa-chevron-circle-down {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center; }
      body .p-datatable .p-datatable-data tr td .p-row-toggler.fa-chevron-circle-down:before {
        content: ""; }
    body .p-datatable .p-datatable-data tr td .p-row-toggler:hover {
      color: #59c429; }
body .p-datatable .p-datatable-data tr.p-widget-header {
  background-color: #fefefe;
  border-color: #f1f1f1; }
  body .p-datatable .p-datatable-data tr.p-widget-header a {
    color: #858585;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
    body .p-datatable .p-datatable-data tr.p-widget-header a:hover {
      color: #59c429; }
body .p-datatable .p-datatable-data.p-datatable-hoverable-rows > tr.p-widget-content:not(.p-state-highlight):hover {
  cursor: pointer;
  background-color: #dddddd;
  border-color: #d1d1d1; }
body .p-datatable .p-datatable-scrollable-header,
body .p-datatable .p-datatable-scrollable-footer {
  background-color: #f0f3f5; }
  body .p-datatable .p-datatable-scrollable-header .p-datatable-scrollable-header-box td,
  body .p-datatable .p-datatable-scrollable-footer .p-datatable-scrollable-header-box td {
    color: #777777; }
body .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-state-highlight):hover {
  background-color: #e2e2e2;
  color: #777777; }
body .p-organizationchart .p-organizationchart-node-content.p-state-highlight .p-node-toggler i {
  color: #295b13; }
body .p-organizationchart .p-organizationchart-line-down {
  background-color: #d1d1d1; }
body .p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #d1d1d1; }
body .p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #d1d1d1; }
body .p-organizationchart .p-organizationchart-node-content {
  border-color: #d1d1d1; }
body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  color: #d1d1d1;
  bottom: -.7em;
  margin-left: -.43em; }
body .p-datagrid .p-datagrid-header {
  padding: 0.5em 1em; }
body .p-datagrid .p-paginator {
  padding: 0.5em 1em; }
body .p-datagrid .p-panel-titlebar {
  border-bottom: 1px solid #eaeaea;
  color: #777777; }
body .p-datalist .p-datalist-header {
  padding: 0.5em 1em; }
body .p-datalist .p-paginator {
  padding: 0.5em 1em; }
body .p-datascroller .p-datascroller-header,
body .p-datascroller .p-datascroller-footer {
  padding: 0.5em 1em; }
body .p-picklist .p-picklist-buttons button {
  font-size: 15px; }
body .p-picklist .p-picklist-caption {
  padding: 0.5em 1em; }
body .p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5em 1em;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .p-picklist .p-picklist-list .p-picklist-item:not(.p-state-highlight):hover {
    background-color: #e2e2e2;
    color: #777777; }
body .p-orderlist .p-orderlist-controls {
  margin-right: 10px; }
  body .p-orderlist .p-orderlist-controls button {
    font-size: 15px; }
body .p-orderlist .p-orderlist-caption {
  padding: 0.5em 1em;
  box-sizing: border-box;
  width: 200px; }
body .p-orderlist .p-orderlist-list {
  box-sizing: border-box; }
  body .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5em 1em;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-state-highlight):hover {
      background-color: #e2e2e2;
      color: #777777; }
body .p-orderlist.p-grid-responsive .p-grid-row .p-orderlist-controls {
  margin-right: 0;
  padding-right: 10px; }
body .p-carousel {
  padding: 0; }
  body .p-carousel .p-carousel-header {
    padding: 0.5em 1em;
    margin: 0;
    border: 0 none;
    border-bottom: 1px solid #eaeaea;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-carousel .p-carousel-header .p-carousel-header-title {
      padding: 0; }
  body .p-carousel .p-carousel-footer {
    padding: 0.5em 1em;
    border: 0 none;
    border-top: 1px solid #eaeaea;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
  body .p-carousel .p-carousel-button {
    margin-top: .4em;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
    body .p-carousel .p-carousel-button.p-carousel-next-button {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center; }
      body .p-carousel .p-carousel-button.p-carousel-next-button:before {
        content: ""; }
    body .p-carousel .p-carousel-button.p-carousel-prev-button {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center; }
      body .p-carousel .p-carousel-button.p-carousel-prev-button:before {
        content: ""; }
    body .p-carousel .p-carousel-button:not(.p-state-disabled):hover {
      color: #59c429; }
  body .p-carousel .p-carousel-page-links {
    margin-top: .4em; }
    body .p-carousel .p-carousel-page-links .p-carousel-page-link {
      color: #858585;
      -moz-transition: color 0.3s;
      -o-transition: color 0.3s;
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }
      body .p-carousel .p-carousel-page-links .p-carousel-page-link:hover {
        color: #6c6c6c; }
      body .p-carousel .p-carousel-page-links .p-carousel-page-link.fa-dot-circle-o {
        color: #59c429; }
body .p-treetable .p-treetable-header {
  padding: 0.5em 1em; }
body .p-treetable .p-treetable-footer {
  padding: 0.5em 1em; }
body .p-treetable thead th {
  background-color: #ffffff;
  padding: 0.5em 1em;
  border-color: #eaeaea;
  color: #777777; }
  body .p-treetable thead th.p-state-hover {
    background-color: #e2e2e2;
    color: #777777; }
  body .p-treetable thead th.p-state-active {
    background-color: #59c429;
    border-color: #4faf25; }
  body .p-treetable thead th .p-column-title {
    font-weight: 700; }
body .p-treetable tfoot td {
  background-color: #ffffff;
  padding: 0.5em 1em;
  border-color: #eaeaea; }
body .p-treetable tbody > div.p-treetable-row-selectable {
  cursor: pointer; }
  body .p-treetable tbody > div.p-treetable-row-selectable:not(.p-state-highlight):hover {
    background-color: #e2e2e2;
    border-color: #dddddd; }
  body .p-treetable tbody > div.p-treetable-row-selectable.p-state-highlight {
    border-color: #4faf25; }
    body .p-treetable tbody > div.p-treetable-row-selectable.p-state-highlight .p-treetable-toggler {
      color: #ffffff; }
body .p-treetable tbody > div td {
  padding: 0.5em 1em; }
  body .p-treetable tbody > div td .p-treetable-toggler {
    font-size: 15px;
    vertical-align: middle;
    display: inline-block;
    float: none;
    color: #777777;
    line-height: inherit; }
body .p-tree {
  padding: .25em .5em; }
  body .p-tree .p-treenode {
    padding: .15em 0; }
    body .p-tree .p-treenode .p-treenode-content {
      padding: 0 0 .25em 0; }
      body .p-tree .p-treenode .p-treenode-content .p-tree-toggler {
        font-size: 15px;
        vertical-align: middle;
        display: inline-block;
        float: none;
        margin: 0; }
      body .p-tree .p-treenode .p-treenode-content .p-treenode-icon {
        vertical-align: middle;
        display: inline-block;
        margin: 0;
        font-size: 15px; }
      body .p-tree .p-treenode .p-treenode-content .p-treenode-label {
        margin: 0;
        vertical-align: middle;
        display: inline-block;
        padding: 0 .25em; }
        body .p-tree .p-treenode .p-treenode-content .p-treenode-label.p-state-highlight {
          background-color: #59c429;
          color: #ffffff; }
      body .p-tree .p-treenode .p-treenode-content .p-chkbox {
        margin: 0 .25em; }
      body .p-tree .p-treenode .p-treenode-content.p-treenode-selectable .p-treenode-label:not(.p-state-highlight):hover {
        background-color: #e2e2e2;
        color: #777777; }
      body .p-tree .p-treenode .p-treenode-content > span {
        line-height: inherit; }
  body .p-tree.p-tree-horizontal {
    padding: .85em 0; }
    body .p-tree.p-tree-horizontal .p-treenode {
      background: url("/assets/layout/layout/images/tth-connector.png") repeat-x scroll center center transparent; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
        padding: 0.5em 1em;
        background-color: #ffffff;
        border: 1px solid #eaeaea; }
        body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-state-highlight {
          background-color: #59c429;
          color: #ffffff; }
        body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-chkbox .p-icon {
          color: #59c429; }
        body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-state-highlight):hover {
          background-color: inherit;
          color: inherit; }
        body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-state-highlight):hover {
          background-color: #e2e2e2;
          color: #777777; }
    body .p-tree.p-tree-horizontal .p-treenode-connector-line {
      background: url("/assets/layout/layout/images/tth-connector.png") repeat-y scroll 0 0 transparent; }
body .fc .fc-toolbar .p-icon-circle-triangle-e {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  height: auto;
  width: auto;
  overflow: visible; }
  body .fc .fc-toolbar .p-icon-circle-triangle-e:before {
    content: ""; }
body .fc .fc-toolbar .p-icon-circle-triangle-w {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  height: auto;
  width: auto;
  overflow: visible; }
  body .fc .fc-toolbar .p-icon-circle-triangle-w:before {
    content: ""; }
body .fc .fc-event-container .fc-event {
  border-color: #59c429;
  background-color: #4faf25; }

body .p-messages.p-messages-info {
  display: none;
  background-color: #ddedf4;
  border-color: #ddedf4;
  color: #393b38; }
  body .p-messages.p-messages-info .p-messages-info-icon {
    font-size: 24px;
    margin-top: -2px; }
body .p-messages.p-messages-warn {
  background-color: #fff7e1;
  border-color: #fff7e1;
  color: #393b38; }
  body .p-messages.p-messages-warn .p-messages-warn-icon {
    font-size: 24px;
    margin-top: -2px; }
body .p-messages.p-messages-error {
  background-color: #ffddd5;
  border-color: #ffddd5;
  color: #393b38; }
  body .p-messages.p-messages-error .p-messages-error-icon {
    font-size: 24px;
    margin-top: -2px; }
body .p-messages.p-messages-success {
  background-color: #C9E7CB;
  border-color: #C9E7CB;
  color: #393b38; }
  body .p-messages.p-messages-success .p-messages-fatal-icon {
    font-size: 24px;
    margin-top: -2px; }
  body .p-messages.p-messages-success .p-messages-close .fa {
    color: #393b38 !important; }
body .p-messages .p-messages-close {
  font-size: 16px;
  color: #393b38; }
body .p-growl {
  top: 85px; }
  body .p-growl .p-growl-item-container {
    opacity: 0.95;
    filter: alpha(opacity=95);
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
    body .p-growl .p-growl-item-container .p-growl-message {
      margin-left: 4em; }
    body .p-growl .p-growl-item-container.p-growl-message-info {
      background-color: #ddedf4;
      border-color: #ddedf4;
      color: #393b38; }
      body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-image {
        font-size: 36px; }
    body .p-growl .p-growl-item-container.p-growl-message-warn {
      background-color: #fff7e1;
      border-color: #fff7e1;
      color: #393b38; }
      body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-image {
        font-size: 36px; }
    body .p-growl .p-growl-item-container.p-growl-message-error {
      background-color: #ffddd5;
      border-color: #ffddd5;
      color: #393b38; }
      body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-image {
        font-size: 36px; }
    body .p-growl .p-growl-item-container.p-growl-message-success {
      background-color: #C9E7CB;
      border-color: #C9E7CB;
      color: #393b38; }
      body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-image {
        font-size: 36px; }

body .p-overlaypanel {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
  body .p-overlaypanel .p-overlaypanel-close {
    background-color: #f1f1f1;
    color: #858585;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    body .p-overlaypanel .p-overlaypanel-close:hover {
      color: #6c6c6c; }
body .p-dialog {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
  body .p-dialog .p-dialog-titlebar {
    padding: 0.5em 1em; }
    body .p-dialog .p-dialog-titlebar .p-dialog-title {
      margin: 0;
      float: none; }
    body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
      color: #858585;
      border: 0 none;
      -moz-transition: color 0.3s;
      -o-transition: color 0.3s;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
      padding: 0;
      margin-left: .35em;
      font-size: 15px; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover {
        color: #6c6c6c; }
  body .p-dialog .p-dialog-content {
    padding: 0.5em 1em; }
  body .p-dialog .p-dialog-buttonpane {
    padding: 0.5em 1em;
    text-align: right; }
    body .p-dialog .p-dialog-buttonpane button {
      margin: 0 .35em 0 0; }
  body .p-dialog .p-dialog-footer {
    border: 0 none;
    border-top: 1px solid #eaeaea; }
  body .p-dialog.p-confirm-dialog .p-dialog-content {
    padding: 1.5em; }
    body .p-dialog.p-confirm-dialog .p-dialog-content > span {
      float: none;
      display: inline-block;
      vertical-align: middle;
      line-height: 13px;
      margin: 0; }
      body .p-dialog.p-confirm-dialog .p-dialog-content > span.p-icon {
        margin-right: .35em;
        font-size: 15px; }
body .p-tooltip .p-tooltip-text {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
body .p-tooltip.p-state-error {
  border: 0 none; }
body .p-lightbox {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
  body .p-lightbox .p-lightbox-caption {
    padding: 0.5em 1em; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
      color: #858585;
      margin: 0; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-close {
      padding: 0;
      color: #858585;
      -moz-transition: color 0.3s;
      -o-transition: color 0.3s;
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }
      body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
        color: #59c429; }
  body .p-lightbox .p-lightbox-content-wrapper {
    overflow: hidden; }
    body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      font-size: 24px;
      margin-left: 4px; }
      body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left:before {
        content: ""; }
      body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left:hover {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2); }
      body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left span {
        display: none; }
    body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      font-size: 24px;
      margin-right: 4px; }
      body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right:before {
        content: ""; }
      body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right:hover {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2); }
      body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right span {
        display: none; }
    body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content.p-lightbox-loading + a {
      display: none; }

body .p-breadcrumb {
  background-color: #f1f1f1;
  padding: 0.5em 1em; }
  body .p-breadcrumb ul li {
    float: none;
    display: inline-block;
    vertical-align: middle; }
    body .p-breadcrumb ul li .p-menuitem-link {
      font-size: 15px;
      color: #858585;
      margin: 0; }
    body .p-breadcrumb ul li.p-breadcrumb-chevron {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center;
      font-size: 15px;
      margin: 0 .45em;
      color: #858585; }
      body .p-breadcrumb ul li.p-breadcrumb-chevron:before {
        content: ""; }
    body .p-breadcrumb ul li:first-child {
      font-size: 15px; }
      body .p-breadcrumb ul li:first-child a.p-icon-home {
        color: #858585;
        margin: 0; }
body .p-steps {
  position: relative; }
  body .p-steps .p-steps-item {
    background-color: transparent;
    text-align: center; }
    body .p-steps .p-steps-item .p-menuitem-link {
      display: inline-block;
      text-align: center;
      background-color: transparent;
      overflow: hidden; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        display: inline-block;
        background-color: #59c429;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        padding: 0.5em 1em;
        font-weight: bold;
        font-size: 1em;
        color: #ffffff;
        position: relative;
        top: 1.1em;
        margin-bottom: 1em;
        border: 1px solid #84dd5c; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        display: block;
        margin-top: 0.45em;
        color: #848484; }
    body .p-steps .p-steps-item.p-state-disabled {
      opacity: 1;
      filter: alpha(opacity=100); }
      body .p-steps .p-steps-item.p-state-disabled .p-steps-number {
        background-color: #f1f1f1;
        border-color: #eaeaea;
        color: #858585; }
    body .p-steps .p-steps-item.p-state-highlight .p-steps-number {
      background-color: #59c429; }
    body .p-steps .p-steps-item.p-state-highlight .p-steps-title {
      font-weight: 700;
      color: #777777; }
    body .p-steps .p-steps-item:last-child .p-menuitem-link {
      display: block; }
  body .p-steps:before {
    content: ' ';
    border: 1px solid #59c429;
    width: 100%;
    top: 45%;
    left: 0;
    display: block;
    position: absolute; }
body .p-menu {
  padding: 0; }
  body .p-menu.p-shadow {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
  body .p-menu .p-menu-list .p-widget-header {
    margin: 1px 0 1px 0;
    width: 100%;
    box-sizing: border-box;
    border: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-menu .p-menu-list .p-widget-header h3 {
      padding: 0.5em 1em;
      font-weight: 400; }
      body .p-menu .p-menu-list .p-widget-header h3 .p-icon-triangle-1-s {
        margin-top: -0.75em; }
    body .p-menu .p-menu-list .p-widget-header .p-icon {
      color: #858585; }
    body .p-menu .p-menu-list .p-widget-header.p-state-hover {
      background-color: #dcdcdc; }
      body .p-menu .p-menu-list .p-widget-header.p-state-hover .p-icon {
        color: #858585; }
    body .p-menu .p-menu-list .p-widget-header:first-child {
      margin-top: 0; }
  body .p-menu .p-menu-list .p-menuitem {
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-menu .p-menu-list .p-menuitem.p-state-hover {
      background-color: #e2e2e2;
      color: #777777; }
      body .p-menu .p-menu-list .p-menuitem.p-state-hover .p-menuitem-link {
        color: #ffffff; }
    body .p-menu .p-menu-list .p-menuitem .p-menuitem-link {
      padding: 0.5em 1em;
      width: 100%;
      box-sizing: border-box;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      color: #777777; }
      body .p-menu .p-menu-list .p-menuitem .p-menuitem-link:hover {
        background-color: #e2e2e2;
        color: #777777; }
      body .p-menu .p-menu-list .p-menuitem .p-menuitem-link .p-menuitem-icon {
        margin-right: .3em;
        position: relative;
        top: 1px; }
      body .p-menu .p-menu-list .p-menuitem .p-menuitem-link .p-submenu-icon {
        line-height: 1.3;
        vertical-align: middle; }
    body .p-menu .p-menu-list .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e2e2e2;
      color: #777777; }
  body .p-menu .p-menu-list .p-menu-child {
    padding: 0; }
    body .p-menu .p-menu-list .p-menu-child.p-shadow {
      -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3); }
  body .p-menu.p-menubar {
    background-color: #f1f1f1;
    border-color: #eaeaea; }
    body .p-menu.p-menubar > .p-menu-list > .p-menuitem {
      width: auto; }
      body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link {
        color: #858585; }
        body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
          color: #858585; }
        body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link:hover {
          color: #777777; }
          body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link:hover .p-menuitem-icon {
            color: #777777; }
      body .p-menu.p-menubar > .p-menu-list > .p-menuitem.p-menubar-options {
        margin-right: 0.6em; }
  body .p-menu.p-megamenu .p-menu-list > table > tbody > tr > td {
    padding: 0; }
  body .p-menu.p-megamenu.p-megamenu-vertical > .p-menu-list .p-menuitem {
    width: 100%; }
  body .p-menu.p-slidemenu .p-slidemenu-backward {
    padding: 0.5em 1em;
    width: 100%;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
    border-top: 1px solid #eaeaea; }
    body .p-menu.p-slidemenu .p-slidemenu-backward span:last-child {
      vertical-align: bottom; }
    body .p-menu.p-slidemenu .p-slidemenu-backward:hover {
      background-color: #dcdcdc; }
    body .p-menu.p-slidemenu .p-slidemenu-backward .p-icon-triangle-1-w {
      background: none;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-indent: 0px !important;
      text-align: center;
      position: relative;
      top: 2px; }
      body .p-menu.p-slidemenu .p-slidemenu-backward .p-icon-triangle-1-w:before {
        content: ""; }
  body .p-menu.p-slidemenu .p-menu-child {
    padding: 0; }
    body .p-menu.p-slidemenu .p-menu-child.p-shadow {
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none; }
body .p-tabmenu {
  border: 0 none; }
  body .p-tabmenu .p-tabmenu-nav {
    padding: 0; }
    body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
      margin: 0;
      position: static;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        color: #858585;
        padding: 0.5em 1em; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon, body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
          vertical-align: middle;
          display: inline-block;
          float: none; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
          color: #858585; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-state-active):not(.p-state-disabled):hover {
        background-color: #dcdcdc; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-state-active):not(.p-state-disabled):hover .p-menuitem-icon {
          color: #858585; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-active .p-menuitem-link {
        background-color: #59c429;
        color: #ffffff; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-active .p-menuitem-link .p-menuitem-icon {
          color: #ffffff; }
body .p-menubutton .p-button.p-button-text-icon-left .p-icon {
  margin-top: -0.75em; }
body .p-panelmenu .p-icon {
  position: static; }
body .p-panelmenu .p-panelmenu-header {
  background-color: #f1f1f1;
  padding: 0;
  font-size: 13px;
  color: #858585;
  margin-bottom: 1px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }
  body .p-panelmenu .p-panelmenu-header a {
    padding: 0.5em 1em;
    color: #858585;
    border-bottom: 1px solid #ffffff; }
  body .p-panelmenu .p-panelmenu-header:not(.p-state-active):hover {
    background-color: #dcdcdc; }
    body .p-panelmenu .p-panelmenu-header:not(.p-state-active):hover .p-icon {
      color: #858585; }
  body .p-panelmenu .p-panelmenu-header.p-state-active {
    background-color: #59c429; }
    body .p-panelmenu .p-panelmenu-header.p-state-active a {
      color: #ffffff; }
    body .p-panelmenu .p-panelmenu-header.p-state-active .p-icon {
      color: #ffffff; }
body .p-panelmenu .p-panelmenu-content .p-menuitem-link {
  padding: 0.5em 1em;
  color: #777777;
  -moz-transition: background-color;
  -o-transition: background-color;
  -webkit-transition: background-color;
  transition: background-color; }
  body .p-panelmenu .p-panelmenu-content .p-menuitem-link:hover {
    background-color: #e2e2e2;
    color: #777777; }
  body .p-panelmenu .p-panelmenu-content .p-menuitem-link > span.p-icon {
    display: inline-block;
    vertical-align: middle;
    left: auto;
    margin-right: 0.3em; }
    body .p-panelmenu .p-panelmenu-content .p-menuitem-link > span.p-icon.p-panelmenu-icon.p-icon-triangle-1-s {
      margin-top: -0.3em; }
  body .p-panelmenu .p-panelmenu-content .p-menuitem-link .p-menuitem-text {
    display: inline-block;
    vertical-align: middle; }

body .p-progressbar {
  border: 0 none;
  background-color: #e3e3e3; }
  body .p-progressbar .p-progressbar-value {
    border: 0 none;
    background: #74d947;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
  body .p-progressbar .p-progressbar-label {
    margin-top: -1px; }
body .p-galleria .p-galleria-nav-prev {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  font-size: 18px;
  margin-bottom: -5px;
  margin-left: -5px; }
  body .p-galleria .p-galleria-nav-prev:before {
    content: ""; }
body .p-galleria .p-galleria-nav-next {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  font-size: 18px;
  margin-bottom: -5px;
  margin-right: -5px; }
  body .p-galleria .p-galleria-nav-next:before {
    content: ""; }
body .p-terminal input {
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-size: 13px; }
