/* Bootstrap & Theme Overrides */
html, body {
    font-family: 'Source Sans Pro','Helvetica Neue',sans-serif;
    font-size: 13px;
}
.label-success {
    background: #59c429;
}

.nav-link:hover {
    cursor: pointer;
}

.dialog-wide, .dialog-tall-wide {
    width: 90vw;
}

.dialog-tall-wide {
    height: 90vh
}

.dialog-max-40em {
    max-width: 40em;
}

.p-padding-sm {
    padding:0.5rem;
}

.p-padding-md {
    padding:1rem;
}

.p-fullwidth {
    width: 100%
}

.p-component, .p-inputtext {
    font-family: 'Source Sans Pro','Helvetica Neue',sans-serif;
}

body .p-panel .p-panel-content, body .p-tabview .p-tabview-panel {
    overflow: hidden;
    padding: 0
}

.nested-panel > p-panel > .p-component > .p-panel-header {
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
}

.nested-panel > p-panel > .p-component > div >.p-panel-content,
.nested-panel .p-fileupload .p-fileupload-buttonbar,
.nested-panel .p-fileupload .p-content,
.nested-panel .p-fileupload .p-fileupload-content{
    border: 0 none;
}

.p-toolbar {
    white-space: nowrap;
    padding: 0 !important;
    justify-content: space-between;
}

.p-toolbar .p-dropdown-label, .p-dataview-header .p-dropdown-label, .p-datatable-header .p-dropdown-label{
    text-align: center;
}

.p-panel .p-panel-header {
    font-weight: bold;
    color: #858585;
    padding: .5rem 1rem;
}

.p-button {
    padding: 0.35rem 0.75rem;
    max-height: 2rem;
    font-weight: bold;
}

.p-panel-header p-header {
    width: 100%;
}

.p-button-icon {
    font-weight: bold;
}

.p-button-icon.fa-binoculars {
    font-weight: normal;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: .25em .5em;
}

.p-inputgroup input:first-child {
    height: 2rem;
}

.p-dataview-content .p-toolbar > div,
.p-panel-content .p-toolbar > div {
    width: 100%;
}

.p-dataview .p-dataview-content {
    border: 1px solid #eaeaea;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.p-dataview .p-dataview-content > .p-grid {
    justify-content: center;
}

.p-dataview .p-dataview-header {
    border-width: 1px;
    border-radius: 3px 3px 0 0;
    padding: .5rem 1rem;
}

.p-panel-header .ui-lg-3 .p-dropdown {
    margin-left: 7px;
}

body .p-dialog .p-dialog-content {
    border-radius: 0 0 3px 3px;
}

body .p-dialog .p-dialog-footer {
    border-top: 0 none;
    margin-top: -3px;
    padding-top: 3px;
}

.p-toolbar .ui-g {
    width: 100%;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th,
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:nth-child(even):not(.p-highlight) {
    background: #fcfcfd;
}

.p-datatable.p-datatable-gridlines .p-datatable-header,
.p-tabview .p-tabview-panels {
    overflow: hidden;
    /*padding:0;*/
}

.form-control {
    box-shadow: none;
}

.p-autocomplete {
    display: flex !important;
}

.p-autocomplete-multiple-container {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

body .p-datepicker .p-datepicker-header .p-datepicker-prev,
body .p-datepicker .p-datepicker-header .p-datepicker-next {
    top: 0;
}

.p-datepicker-inline .p-datepicker-header {
    background: transparent;
}

.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
    background-color: #59c429;
    color: #fff;
    font-weight: bold;
    margin-bottom: 1px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: #e9ecef;
    border-radius: 0;
}

.p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link:hover {
    background: #dee2e6!important;
}


.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #59c429;
    border-color: #4faf25;
    color: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight:hover,
body .p-button, .p-button-primary, .p-button.p-button-success {
    background: #59c429;
    border-color: #59c429;
    color: #ffffff;
}

.p-button:hover, .p-button-primary:hover, .p-button-success:hover,
.p-button:enabled:hover {
    background-color: #4faf25;
    border-color:  #4faf25;
}

body .p-paginator .p-paginator-first:hover, body .p-paginator .p-paginator-prev:hover,
body .p-paginator .p-paginator-next:hover, body .p-paginator .p-paginator-last:hover {
    background: transparent !important;
}

.p-accordion-header-link,  .p-accordion-header-link:hover, .p-accordion-header-link:active,
.p-tabview-nav-link, .p-tabview-nav-link:hover, .p-tabview-nav-link:active,
.p-tabview-nav-link:focus{
    text-decoration: none;
}

.p-menu .p-submenu-header {
    background: #e9ecef;
}

.p-steps-item.p-disabled {
    opacity: 1
}

body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    padding:0;
    border-color: #59c429;
}

body .p-steps .p-steps-item.p-disabled .p-menuitem-link .p-steps-number {
    padding:0;
    background: #e9ecef;
    border-color: #e9ecef;
    color: #777;
}

.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
    background: #59c429;
    color: #fff;
    font-weight: bold;
}

body .p-datepicker table td span.p-highlight:hover {
    background: #4faf25;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    background: #777;
}

body .p-menu .p-menu-list .p-menuitem .p-menuitem-link .p-menuitem-icon {
    max-width: 1em;
}

body .p-menu .p-menu-list .p-menuitem.active-menuitem {
    background: #E8F5E9;
}

.p-menu .p-menuitem.active-menuitem .p-menuitem-link .p-menuitem-icon,
.p-menu .p-menuitem.active-menuitem .p-menuitem-link .p-menuitem-icon:hover {
    color:  #4faf25;
}


hr {
    border: 0;
    clear:both;
    display:block;
    width: 96%;
    background-color:#ddd;
    height: 1px;
}

.p-calendar .p-datepicker {
    display: flex;
    flex-direction: column;
}

.layout-wrapper .topbar #menu-button {
    width: 60px;
}

.layout-content {
    padding: 60px 0 0 0 !important;
}

.splash-logo { /* TODO: This is not ideal, consider fixing the image */
    margin-left: -25px;
    margin-top: -17px;
}

.p-widget-header {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.p-toolbar.p-widget-header {
    overflow: visible;
}

.p-tabview-panel.p-widget-content {
    overflow: hidden;
    padding: 0 !important;
}

.p-dataview-content.p-widget-content {
    padding: 0.25em !important;
}

.p-dataview-content.p-widget-content div {
    justify-content: center;
}

.p-panel .p-panel-titlebar {
    padding: 0.5em !important;
}

.p-dataview-content .p-panel-title,
.p-panel-content .p-panel-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
    font-size: 1rem;
    
}
.p-panel .p-panel-header .p-panel-title {
    line-height: 1.5;
}

.p-panel-content.p-widget-content {
    padding: 0 !important;
    overflow: hidden;
}

container .p-panel,
resource .p-panel {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

container .p-toolbar,
resource .p-toolbar {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-control {
    padding: 4px 8px;
    height: 28px;
}

.p-dropdown .p-dropdown-clear-icon {
    right: 3.5em !important;
}

.p-dropdown label.p-dropdown-label {
    text-align: center;
}

p-tabView {
    padding: 0 !important;
}

.p-paginator {
    /*border: none;*/
    border: 1px solid #eee;
}

.p-dataview .p-paginator,  .p-datatable .p-paginator{
    background: #f8f9fa;
}

.p-datatable.p-datatable-gridlines .p-paginator {
    border-color: #dddddd;
}

.p-paginator-top, .p-dataview .p-paginator-top, .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-no-toolbar .p-paginator-top {
    border-width: 1px 1px 0 1px;
    border-radius: 3px 3px 0 0;
}

.p-paginator-bottom, .p-dataview .p-paginator-bottom, .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 3px 3px;
}

.table-header {
    border: 1px solid #dddddd !important;
}

p-toolbar .p-widget-header {
    border: 1px solid #dddddd !important;
}

p-table .p-widget-header {
    border: 1px solid #dddddd !important;
}

p-table .p-table-caption {
    border-bottom: 0;
}

p-panel p-table .p-widget-header {
    border: none !important;
}

p-panel .p-table .p-table-thead>tr>th:first-child, 
p-panel .p-table .p-table-tbody>tr>td:first-child, 
p-panel .p-table .p-table-tfoot>tr>td:first-child,
.p-datatable-nested .p-datatable-thead>tr>th:first-child,
.p-datatable-nested .p-datatable-tbody>tr>td:first-child,
.p-datatable-nested .p-datatable-tfoot>tr>td:first-child{
    border-left: 0 none;
}

p-panel .p-table .p-table-thead>tr>th:last-child, 
p-panel .p-table .p-table-tbody>tr>td:last-child, 
p-panel .p-table .p-table-tfoot>tr>td:last-child,
.p-datatable-nested .p-datatable-thead>tr>th:last-child,
.p-datatable-nested .p-datatable-tbody>tr>td:last-child,
.p-datatable-nested .p-datatable-tfoot>tr>td:last-child {
    border-right: 0  none;
}

.p-datatable-nested .p-datatable-thead>tr:first-child>th{
    border-top: 0 none;
}

.p-datatable-nested.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-left: 0 none;
    border-right:0 none;
    border-bottom: 1px solid #e9ecef;
}
.p-datatable-nested.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border: 0 none;
}

/* Theme Assertions (Morpheus Theme Green) */
/* These are properties of the theme that are being overwritten or negated by other sections of CSS,
   these should be removed or changed as the theme changes */

.p-widget-header {
    background: unset !important;
    background-color: #f1f1f1 !important;
}

label {
    color: #777777;
    margin-bottom: 2px;
}

.p-tabview-nav.p-widget-header {
    background-color: transparent !important;
}

.p-tabview-nav li {
    border: none !important;
}

.p-table .p-table-tbody>tr:nth-child(even), .p-table .p-table-thead>tr>th {
    background-color: #fafafb;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:nth-child(even):not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
}

.p-table .p-table-tbody>tr:nth-child(even).p-state-highlight {
    background-color:#59c429;
}

.p-table .p-table-thead>tr>th, .p-table .p-table-tbody>tr>td, .p-table .p-table-tfoot>tr>td {
    border: 1px solid #dddddd;
}


.strong-highlight .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #4faf25;
    color: #fff;
}

/* Grid Overrides */

.row {
    margin: 0;
}

/* Owl Carousel Overrides */

.owl-carousel .owl-item img {
    max-height: 90%;
    width: auto !important;
    max-width: 100%;
}

/* Leaflet Overrides */

.leaflet-pane {
    z-index: 2;
}

.leaflet-tile-pane {
    z-index: 4;
}

.leaflet-shadow-pane {
    z-index: 5;
}

.leaflet-overlay-pane {
    z-index: 4;
}

.leaflet-map-pane canvas {
    z-index: 1;
}

.leaflet-marker-pane {
    z-index: 6;
}

.leaflet-tooltip-pane {
    z-index: 7;
}

.leaflet-popup-pane {
    z-index: 7;
}

.leaflet-control {
    z-index: 8;
}

.leaflet-top, .leaflet-bottom {
    z-index: 10;
}

/* Custom Style */

.top-level-card { /* This is to deal with the layout-content padding change */
    margin: 16px;
}

.top-level-multicard { /* This is to deal with the layout-content padding change when there are multiple top level cards */
    margin: 10px;
}

.stacked-card { /* This is for when a card is in a grid container */
    margin-bottom: 0;
}

.card-content { /* This is to deal with the excess padding from card and ui-g together */
    margin: -0.5em;
    margin-top: 0;
}

application-form,
distribution-form,
version-form,
configuration-form {
    width: 100%;
}

/* Calendar inputs - prevent field overlap */
.p-calendar.p-calendar-w-btn input {
    margin-right:-1.75em;
    height: 2em;
}

/* PrimeNG 9 Overrides */

body .p-widget-overlay {
    opacity: 1;
    background: transparent;
}

body .p-widget-overlay::after {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(66, 66, 66, 0.7);
}

/* .p-dataview-content .p-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
} */

.p-grid {
    margin-top: 0!important;
}


.p-dropdown .p-dropdown-label-container {
    display: block;
    width: 100%;
    text-align: center;
}

.p-paginator .p-paginator-page, .p-paginator .p-paginator-pages, 
.p-paginator .p-paginator-next, .p-paginator .p-paginator-last, 
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, 
.p-paginator .p-paginator-current {
    height: auto !important; 
}

.p-paginator .p-paginator-icon {
    font-size: 1.25em;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
    padding: .5rem;
    font-weight: bold;
}


/*.p-button {
    margin-right: .1em;
}*/

.p-button.p-button-icon-only {
    width: 2em;
}

.p-button-icon-only .p-button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    width: 1em;
    height: 1em;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    background-color: #d9534f;
    border-color: #d9534f;
    color: #fff;
}

.p-button.p-button-danger:enabled:hover, .p-button.p-button-danger:focus, 
.p-splitbutton.p-button-danger .p-button:enabled:hover, 
.p-splitbutton.p-button-danger .p-button:focus {
    background-color: #d43f3a !important;
    border-color: #d43f3a;
}

.p-button.p-button-danger:enabled:active, .p-splitbutton.p-button-danger .p-button:enabled:active {
    background-color: #c9302c !important;
    border-color: #c9302c ;
}

.p-multiselect .p-multiselect-label {
    font-weight: bold!important;
}

.p-toast, .p-toast .p-toast-close-icon {
    color: #393939;
    text-decoration: none;
}

.p-toast-top-right {
    top: 80px !important;
}

.p-toast-message {
    background: #eaeaea;
}

.p-toast-message-success,
.p-toast-message-success {
    background: #C9E7CB;
}

.p-toast-message-warn {
    background: #fff7e1;
}

.p-toast-message-error {
    background: #ffddd5;
}

/*general overrides*/
.no-border {
    border:0 none;
}

.layout-wrapper .layout-sidebar .layout-tabmenu .layout-tabmenu-contents .layout-submenu-content .nano > .nano-content {
    overflow-y: auto!important;
}
