/* 
 * New Grid CSS
 * Only necessary to add if your PrimeFaces version is older than 5.3.14
 */
.ui-g {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
            
.ui-g:after {
    clear: both;
    content: "";
    display: table;
}
            
.ui-g-1,
.ui-g-2,
.ui-g-3,
.ui-g-4,
.ui-g-5,
.ui-g-6,
.ui-g-7,
.ui-g-8,
.ui-g-9,
.ui-g-10,
.ui-g-11,
.ui-g-12 {
    float: left;
    box-sizing: border-box;
    padding: 0.5em;
}

.ui-g-1 {
    width: 8.3333%;
}

.ui-g-2 {
    width: 16.6667%;
}

.ui-g-3 {
    width: 25%;
}

.ui-g-4 {
    width: 33.3333%;
}

.ui-g-5 {
    width: 41.6667%;
}

.ui-g-6 {
    width: 50%;
}

.ui-g-7 {
    width: 58.3333%;
}

.ui-g-8 {
    width: 66.6667%;
}

.ui-g-9 {
    width: 75%;
}

.ui-g-10 {
    width: 83.3333%;
}

.ui-g-11 {
    width: 91.6667%;
}

.ui-g-12 {
    width: 100%;
}

@media screen and (max-width: 40em) {
    .ui-sm-1,
    .ui-sm-2,
    .ui-sm-3,
    .ui-sm-4,
    .ui-sm-5,
    .ui-sm-6,
    .ui-sm-7,
    .ui-sm-8,
    .ui-sm-9,
    .ui-sm-10,
    .ui-sm-11,
    .ui-sm-12 {
        padding: 0.5em;
    }

    .ui-sm-1 {
        width: 8.3333%;
    }

    .ui-sm-2 {
        width: 16.6667%;
    }

    .ui-sm-3 {
        width: 25%;
    }

    .ui-sm-4 {
        width: 33.3333%;
    }

    .ui-sm-5 {
        width: 41.6667%;
    }

    .ui-sm-6 {
        width: 50%;
    }

    .ui-sm-7 {
        width: 58.3333%;
    }

    .ui-sm-8 {
        width: 66.6667%;
    }

    .ui-sm-9 {
        width: 75%;
    }

    .ui-sm-10 {
        width: 83.3333%;
    }

    .ui-sm-11 {
        width: 91.6667%;
    }

    .ui-sm-12 {
        width: 100%;
    }
}

@media screen and (min-width: 40.063em) {
    .ui-md-1,
    .ui-md-2,
    .ui-md-3,
    .ui-md-4,
    .ui-md-5,
    .ui-md-6,
    .ui-md-7,
    .ui-md-8,
    .ui-md-9,
    .ui-md-10,
    .ui-md-11,
    .ui-md-12 {
        padding: 0.5em;
    }

    .ui-md-1 {
        width: 8.3333%;
    }

    .ui-md-2 {
        width: 16.6667%;
    }

    .ui-md-3 {
        width: 25%;
    }

    .ui-md-4 {
        width: 33.3333%;
    }

    .ui-md-5 {
        width: 41.6667%;
    }

    .ui-md-6 {
        width: 50%;
    }

    .ui-md-7 {
        width: 58.3333%;
    }

    .ui-md-8 {
        width: 66.6667%;
    }

    .ui-md-9 {
        width: 75%;
    }

    .ui-md-10 {
        width: 83.3333%;
    }

    .ui-md-11 {
        width: 91.6667%;
    }

    .ui-md-12 {
        width: 100%;
    }
}

@media screen and (min-width: 64.063em) {
    .ui-lg-1,
    .ui-lg-2,
    .ui-lg-3,
    .ui-lg-4,
    .ui-lg-5,
    .ui-lg-6,
    .ui-lg-7,
    .ui-lg-8,
    .ui-lg-9,
    .ui-lg-10,
    .ui-lg-11,
    .ui-lg-12 {
        padding: 0.5em;
    }

    .ui-lg-1 {
        width: 8.3333%;
    }

    .ui-lg-2 {
        width: 16.6667%;
    }

    .ui-lg-3 {
        width: 25%;
    }

    .ui-lg-4 {
        width: 33.3333%;
    }

    .ui-lg-5 {
        width: 41.6667%;
    }

    .ui-lg-6 {
        width: 50%;
    }

    .ui-lg-7 {
        width: 58.3333%;
    }

    .ui-lg-8 {
        width: 66.6667%;
    }

    .ui-lg-9 {
        width: 75%;
    }

    .ui-lg-10 {
        width: 83.3333%;
    }

    .ui-lg-11 {
        width: 91.6667%;
    }

    .ui-lg-12 {
        width: 100%;
    }
}

@media screen and (min-width: 90.063em) {
    .ui-xl-1,
    .ui-xl-2,
    .ui-xl-3,
    .ui-xl-4,
    .ui-xl-5,
    .ui-xl-6,
    .ui-xl-7,
    .ui-xl-8,
    .ui-xl-9,
    .ui-xl-10,
    .ui-xl-11,
    .ui-xl-12 {
        padding: 0.5em;
    }

    .ui-xl-1 {
        width: 8.3333%;
    }

    .ui-xl-2 {
        width: 16.6667%;
    }

    .ui-xl-3 {
        width: 25%;
    }

    .ui-xl-4 {
        width: 33.3333%;
    }

    .ui-xl-5 {
        width: 41.6667%;
    }

    .ui-xl-6 {
        width: 50%;
    }

    .ui-xl-7 {
        width: 58.3333%;
    }

    .ui-xl-8 {
        width: 66.6667%;
    }

    .ui-xl-9 {
        width: 75%;
    }

    .ui-xl-10 {
        width: 83.3333%;
    }

    .ui-xl-11 {
        width: 91.6667%;
    }

    .ui-xl-12 {
        width: 100%;
    }
}

.ui-g-nopad {
    padding: 0px;
}